import React, { useMemo } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { view, status } from "../_mocks/dummyData";
import { useOrderUIContext } from "../OrderUIContext";
import { MenuItem, FormControl, Select } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchAgent } from "../../ContactDeals/_redux/contact/contactAction";
import { dealsFranchiseListing } from "../../ContactDeals/_redux/deals/dealsCrud";
import { useState } from "react";
import { useIntl } from "react-intl";
import { OrderSearchForm } from "./OrderSearchForm";
import RefreshIcon from "@material-ui/icons/Refresh";
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from "@material-ui/core/styles";
import { fetchStats } from "../_redux/order/orderAction";
import { InputGroup } from "react-bootstrap";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DatePicker from "react-datepicker";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  refreshIcon: {
    transition: "transform 0.3s linear",
    animation: "$rotate 1s linear infinite",
  },
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));
const prepareFilter = (queryParams, values) => {
  const {
    searchText,
    search_view,
    search_agent,
    search_franchise,
    search_status,
    searchTextBook,
    from_date,
    to_date,
    status,
  } = values;
  const newQueryParams = { ...queryParams };

  const filter = {};

  filter.search = searchText || searchTextBook;
  filter.search_view = search_view ? search_view : "";
  filter.search_agent = search_agent ? search_agent : "";
  filter.search_franchise = search_franchise ? search_franchise : "";
  filter.search_status = search_status ? search_status : "";
  filter.to_date = to_date ? to_date : "";
  filter.status = status ? status : "";
  filter.from_date = from_date ? from_date : "";

  newQueryParams.filter = filter;
  return newQueryParams;
};
export function OrderFilter({ location, statsData }) {
  const intl = useIntl();
  const orderUIContext = useOrderUIContext();
  const orderUIProps = useMemo(() => {
    return {
      setQueryParams: orderUIContext.setQueryParams,
      queryParams: orderUIContext.queryParams,
    };
  }, [orderUIContext]);
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(orderUIProps.queryParams, values);

    if (!isEqual(newQueryParams, orderUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      orderUIProps.setQueryParams(newQueryParams);
    }
  };
  const dispatch = useDispatch();
  const [franchises, setFranchise] = useState([]);
  const { currentState, role } = useSelector(
    (state) => ({
      currentState: state.contact.agent,
      role: state.auth.user.roles,
    }),
    shallowEqual
  );
  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(fetchAgent());
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch]);
  const handleFranchiseList = () => {
    dealsFranchiseListing().then((res) => {
      setFranchise(res.data.data);
    });
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      handleFranchiseList();
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);
  const classes = useStyles();
  const [rotating, setRotating] = useState(false);
  useEffect(() => {
    let timeoutId;
    if (rotating) {
      timeoutId = setTimeout(() => {
        setRotating(false);
      }, 5000);
    }

    return () => clearTimeout(timeoutId);
  }, [rotating]);

  const handleRefreshClick = () => {
    dispatch(fetchStats());
    // setRotating(true);
  };

  return (
    <>
      <Formik
        initialValues={{
          status: "", // values => All=""/Susspended=0/Active=1/Pending=2
          type: "", // values => All=""/Business=0/Individual=1
          searchText: "",
          search_view: "",
          search_agent: "",
          search_franchise: "",
          search_status: "",
          searchTextBook: "",
          from_date: "",
          to_date: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,

          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row d-flex justify-content-between">
              {location.pathname === "/create-return" ||
              location.pathname === "/create-access" ? (
                <div className="col-lg-12 mb-2 d-flex justify-content-between">
                  {/* <div> */}
                  <div className="mt-3 d-inline-block" style={{ width: "31%" }}>
                    <label>Please search for the corresponding Order : </label>
                  </div>
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      alignSelf: "baseline",
                    }}
                  >
                    <input
                      style={{ paddingRight: "40px" }}
                      type="text"
                      className="form-control"
                      name="searchTextBook"
                      placeholder={"Enter Order Number | Customer Name"}
                      onBlur={handleBlur}
                      value={values.searchTextBook}
                      onChange={(e) => {
                        setFieldValue("searchText", "");
                        setFieldValue("searchTextBook", e.target.value);
                        setTimeout(() => {
                          handleSubmit();
                        }, 1000);
                      }}
                    />
                    <i
                      className="fa fa-search orderTableSearch"
                      aria-hidden="true"
                    ></i>
                  </div>
                  {/* </div> */}
                </div>
              ) : (
                ""
              )}
              {((role && role?.length && role[0] === "super-admin") ||
                (role &&
                  role?.length &&
                  role[0]?.name === "franchise-owner")) && (
                <div className="col-lg-12">
                  <div
                    className="row orderTableCard p-2"
                    style={{ fontSize: "13px" }}
                  >
                    <div className="col-lg-2 col-md-6  d-flex  ">
                      Yesterday:&nbsp;
                      <strong>{statsData?.collectionsYesterday || "-"}</strong>
                    </div>
                    <div className="col-lg-1 col-md-6  d-flex timeLineOrder">
                      Today:&nbsp;
                      <strong>{statsData?.collectionsToday || "-"}</strong>
                    </div>
                    <div className="col-lg-1 col-md-6  d-flex  ">
                      Online&nbsp;Today:&nbsp;
                      <strong>{statsData?.onlineOrdersToday || "-"}</strong>
                    </div>
                    <div
                      className="col-lg-2 col-md-6  d-flex timeLineOrderLast  "
                      style={{ justifyContent: "center" }}
                    >
                      Last&nbsp;Month:&nbsp;
                      <strong>
                        {statsData?.collectionsPreviousMonth || "-"}
                      </strong>
                    </div>
                    <div className="col-lg-2 col-md-6  d-flex  ">
                      This&nbsp;Month:&nbsp;
                      <strong>{statsData?.collectionsThisMonth || "-"}</strong>
                    </div>
                    <div className="col-lg-2 col-md-6  d-flex  ">
                      Online&nbsp;This&nbsp;Month:&nbsp;
                      <strong>
                        {statsData?.onlineCollectionsThisMonth || "-"}
                      </strong>
                    </div>
                    <div className="col-lg-2 col-md-12  d-flex    justify-content-between timeLineOrderTime">
                      <span>
                        Time&nbsp;Since&nbsp;Last&nbsp;Order:&nbsp;&nbsp;
                        <strong>{statsData?.elapsedTime || "-"}</strong>
                      </span>
                      &nbsp;&nbsp;
                      <div
                        onClick={handleRefreshClick}
                        style={{ cursor: "pointer", marginTop: "-2px" }}
                      >
                        <RefreshIcon
                          className={`${
                            statsData?.loading ? classes.refreshIcon : ""
                          }`}
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              )}
              <div className="col-lg-10">
                <div className="row">
                  <div className="col-lg-3 ">
                    <FormControl fullWidth>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        className="form-control"
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        inputProps={{ "aria-label": "Without label" }}
                        displayEmpty
                        onChange={(e) => {
                          setFieldValue("search_view", e.target.value);
                          handleSubmit();
                        }}
                        onBlur={handleBlur}
                        value={values.search_view}
                      >
                        <MenuItem value="">
                          {intl.formatMessage({ id: "View" })}
                        </MenuItem>
                        {view.map((data, index) => (
                          <MenuItem key={index} value={data.id}>
                            {data.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-lg-3  d-flex order-flt ">
                    <span className="mt-3">
                      {intl.formatMessage({ id: "Franchise" })}:&nbsp;{" "}
                    </span>
                    <FormControl fullWidth>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        className="form-control"
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        onChange={(e) => {
                          setFieldValue("search_franchise", e.target.value);

                          handleSubmit();
                        }}
                        onBlur={handleBlur}
                        value={values.search_franchise}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="">
                          {intl.formatMessage({ id: "All.Franchises" })}
                        </MenuItem>
                        {franchises?.map((data, index) => (
                          <MenuItem key={index} value={data.id}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-lg-3 d-flex order-flt ">
                    <span className="mt-3">
                      {intl.formatMessage({ id: "Agent" })}:&nbsp;{" "}
                    </span>
                    <FormControl fullWidth>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        className="form-control"
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        onChange={(e) => {
                          setFieldValue("search_agent", e.target.value);
                          handleSubmit();
                        }}
                        onBlur={handleBlur}
                        value={values.search_agent}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="">
                          {intl.formatMessage({ id: "Agent" })}
                        </MenuItem>
                        {currentState &&
                          currentState.map((data, index) => (
                            <MenuItem key={index} value={data.id}>
                              {data.first_name +
                                " " +
                                (data.last_name ? data.last_name : "")}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-lg-3  d-flex order-flt mb-4">
                    <span className="mt-3">
                      {intl.formatMessage({ id: "Status" })}:&nbsp;{" "}
                    </span>
                    <FormControl fullWidth>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        className="form-control"
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        onChange={(e) => {
                          setFieldValue("search_status", e.target.value);

                          handleSubmit();
                        }}
                        onBlur={handleBlur}
                        value={values.search_status}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="">Status</MenuItem>
                        {status.map((data, index) => (
                          <MenuItem key={index} value={data.id}>
                            {data.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-lg-5  d-flex order-flt mb-4">
                    <span className="mt-3">
                      Created&nbsp;By&nbsp;Date:&nbsp;
                    </span>{" "}
                    <InputGroup className="calendr">
                      <DatePicker
                        fullWidth
                        fixedHeight
                        className="form-control"
                        wrapperClassName="datepicker"
                        dateFormat="dd-MM-yyyy"
                        placeholderText="From Date"
                        selected={Date.parse(values?.from_date)}
                        startDate={Date.parse(values?.from_date)}
                        endDate={Date.parse(values?.to_date)}
                        // selectsRange
                        // isClearable
                        onChange={(dates) => {
                          // const [start, end] = dates;
                          setFieldValue(
                            "from_date",
                            dates ? moment(dates).format("yyyy-MM-DD") : ""
                          );

                          if (dates === null) {
                            handleSubmit();
                          }
                        }}
                      />

                      <CalendarTodayIcon />
                    </InputGroup>
                    <InputGroup className="calendr">
                      <DatePicker
                        fullWidth
                        fixedHeight
                        className="form-control"
                        wrapperClassName="datepicker"
                        dateFormat="dd-MM-yyyy"
                        placeholderText="To Date"
                        selected={Date.parse(values?.to_date)}
                        startDate={Date.parse(values?.from_date)}
                        endDate={Date.parse(values?.to_date)}
                        // selectsRange
                        onChange={(dates) => {
                          // setFieldValue(
                          //   "from_date",
                          //   start ? moment(start).format("yyyy-MM-DD") : ""
                          // );
                          setFieldValue(
                            "to_date",
                            dates ? moment(dates).format("yyyy-MM-DD") : ""
                          );
                          // if (dates || (start === null && end === null)) {
                          handleSubmit();
                          // }
                        }}
                      />

                      <CalendarTodayIcon />
                    </InputGroup>
                  </div>
                  {/* <div className="col-lg-3  d-flex order-flt ">
                    <span className="mt-3">
                      Status:
                    </span>
                    <FormControl fullWidth>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        className="form-control"
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        onChange={(e) => {
                          setFieldValue("status", e.target.value);

                          handleSubmit();
                        }}
                        onBlur={handleBlur}
                        value={values.search_franchise}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="">
                          Status:&nbsp;
                        </MenuItem>
                        <MenuItem value="active">
                        Active
                        </MenuItem>
                        <MenuItem value="overdue">
                        Overdue
                        </MenuItem>
                        <MenuItem value="canceled">
                        Cancelled
                        </MenuItem>
                       
                      </Select>
                    </FormControl>
                  </div> */}
                </div>
              </div>
              {/* {location.pathname !== "/create-return" &&
              location.pathname !== "/create-access" ? ( */}
              <div className="col-lg-2 ">
                <OrderSearchForm
                  intl={intl}
                  handleBlur={handleBlur}
                  values={values}
                  setFieldValue={setFieldValue}
                  handleSubmit={handleSubmit}
                />
                {/* <div style={{ position: "relative" }}>
                  <input
                    style={{ paddingRight: "40px" }}
                    type="text"
                    className="form-control"
                    name="searchText"
                    placeholder={intl.formatMessage({ id: "Search" })}
                    onBlur={handleBlur}
                    value={values.searchText}
                    onChange={(e) => {
                      setFieldValue("searchTextBook", "");
                      setFieldValue("searchText", e.target.value);
                      setTimeout(() => {
                        handleSubmit();
                      }, 1000);
                    }}
                  />
                  <i
                    style={{
                      position: "absolute",
                      top: "50%",
                      transform: "translateY(-50%)",
                      right: "15px",
                    }}
                    className="fa fa-search"
                    aria-hidden="true"
                  ></i>
                </div> */}
              </div>
              {/* ) : (
                ""
              )} */}
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
