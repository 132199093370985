/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { InputGroup, Modal } from "react-bootstrap";
import React, { useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import {
  fetchBillingCardDetail,
  fetchMyOrderDetailCard,
  fetchMyOrderTimeline,
  // fetchOrderSummary,
} from "../_redux/order/orderAction";
import { LoadingCustome } from "../../../../_metronic/_partials/controls/LoadingCustome";
// import { CompetitorModalSchema } from "../order-form/validationSchema";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  // FormLabel,
} from "@material-ui/core";
import { autoCollect, pausePay } from "../_redux/order/orderCrud";
import { capitalizeFirstLetter } from "../../../utils/canvaCloseFunction";
import DatePicker from "react-datepicker";
import moment from "moment";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";



const PausePaymentModal = ({ dialog, setDialog, orderDetail, billingCard }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const initialValues = {
    order_id: orderDetail?.id || "",
    auto_collection: "off",
    invoice_behavior:"",
    pause_until:"",
    pause:"",
  };
  //   const { accessFeeList } = useSelector(
  //     (state) => ({
  //       accessFeeList: state.order.bookingAccessCharges,
  //     }),
  //     shallowEqual
  //   );

  // Call on mount of componet to get Booking
  //   useEffect(() => {
  //     if (orderDetail?.id) {
  //       dispatch(
  //         fetchBookingAccessCharges(orderDetail?.id, orderDetail?.franchise_id)
  //       );
  //     }
  //   }, [dispatch, orderDetail?.id]);

  const handleSubmit = (values) => {
    setSnack({
      call: false,
      type: "",
      message: "",
    });
    setIsLoading(true);
    delete values.pause
    pausePay(values)
      .then((res) => {
        dispatch(fetchMyOrderDetailCard(orderDetail?.id));
        dispatch(fetchMyOrderTimeline(orderDetail?.id));
        dispatch(
          fetchBillingCardDetail(
            orderDetail?.franchise_id,
            orderDetail?.subscription_data?.id,
            "",
            orderDetail?.customer_chargebee_id,
            orderDetail?.id
          )
        );
        setIsLoading(false);
        setSnack({
          call: true,
          type: "success",
          message: res?.data?.message || "",
        });
        setTimeout(() => {
          setDialog({ check: false });
          setSnack({
            call: false,
            type: "",
            message: "",
          });
        }, 3000);
      })
      .catch((error) => {
        setIsLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: error?.response?.data?.message || "",
        });
      });
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    // validationSchema: CompetitorModalSchema,
    onSubmit: handleSubmit,
  });

  const handleClose = () => {
    setDialog({ check: false });
  };
  const labelSame = (
    <div className="mb-4">
      <h7>
      <strong>Keep invoice as drafts</strong>
      </h7>
      <br/>
      <span>For businesses currently offering services but waiting to collect</span>
      <span>payments</span>
    </div>
  );
  const labelOn = (
    <div className="mb-4">
      <h7><strong>Mark invoice uncollectible</strong></h7>
      <br/>
      <span>For businesses currently offering services for</span>
    </div>
  );
  const labelOff = (
    <div>
      <h7><strong>Void invoices</strong></h7>
      <br/>
      <span>For businesses not currently offering services.</span>
    </div>
  );
  const labelIn = (
    <div>
      <h7><strong>Indefinite</strong></h7>
    </div>
  );
  const labeldate = (
    <div>
      <h7><strong>Until a custom date</strong></h7>
    </div>
  );
  return (
    <Modal
      show={dialog.check}
      aria-labelledby="example-modal-sizes-title-lg"
      onHide={handleClose}
    >
      <Modal.Header className="d-flex justify-content-between">
        <Modal.Title id="example-modal-sizes-title-lg">
        Pause Payment Collection
        </Modal.Title>
        <span
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          onClick={handleClose}
        >
          <i className="ki ki-close icon-sm text-muted"></i>
        </span>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          {isLoading && <LoadingCustome />}
          <FormControl component="fieldset">
            <h6>Pause Duration</h6>
            <RadioGroup
              aria-label="gender"
              name="pause"
              value={formik.values?.pause}
              onChange={(e) => {
                formik.setFieldValue("pause", e.target.value);
              }}
            >
              <FormControlLabel
                value="off"
                control={<Radio />}
                label={labelIn}
              />
              <FormControlLabel
                value="on"
                control={<Radio />}
                label={labeldate}
              />
              {/* {formik?.values?.pause == "on"&&
                <div >
                 
                    <InputGroup className="calendr">
                      <DatePicker
                        fullWidth
                        fixedHeight
                        className="form-control"
                        wrapperClassName="datepicker"
                        dateFormat="dd-MM-yyyy"
                        placeholderText="From Date"
                        selected={Date.parse(formik?.values?.from_date)}
                        startDate={Date.parse(new Date())}
                        // endDate={Date.parse(values?.to_date)}
                        // selectsRange
                        // isClearable
                        onChange={(dates) => {
                          // const [start, end] = dates;
                          formik.setFieldValue(
                            "pause_until",
                            dates ? moment(dates).format("yyyy-MM-DD") : ""
                          );

                          if (dates === null) {
                            handleSubmit();
                          }

                        }}
                      />


                      <CalendarTodayIcon />
                    </InputGroup>
                
                  </div>
} */}
            </RadioGroup>
          </FormControl>
          <FormControl component="fieldset">
            <h6>Invoice behavior</h6>
            <RadioGroup
              aria-label="gender"
              name="invoice_behavior"
              value={formik.values?.invoice_behavior}
              onChange={(e) => {
                formik.setFieldValue("invoice_behavior", e.target.value);
              }}
            >
              <FormControlLabel
                value="keep_as_draft"
                control={<Radio />}
                label={labelSame}
              />
              <FormControlLabel
                value="mark_uncollectible"
                control={<Radio />}
                label={labelOn}
              />
              <FormControlLabel
                value="void"
                control={<Radio />}
                label={labelOff}
              />
            </RadioGroup>
          </FormControl>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => handleClose()}
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              //   disabled={isLoading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
            >
              Save
            </button>
          </div>
        </Modal.Footer>
      </form>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </Modal>
  );
};

export default PausePaymentModal;
