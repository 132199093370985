/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import { Form, InputGroup } from "react-bootstrap";
import React, { useState } from "react";
import { SendQuoteSchema } from "../order-form/validationSchema";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { sendQuote } from "../_redux/order/orderCrud";
import { useFormik } from "formik";
import { FormControl, MenuItem, Select } from "@material-ui/core/";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import DatePicker from "react-datepicker";
import TimePicker from "rc-time-picker";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { useIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import moment from "moment";
import { useEffect, useCallback, useRef } from "react";
import { createTask } from "../../Tasks/_redux/TasksCrud";
import { WeekCounter } from "../../../utils/commonFunction";
import { Switch } from "@material-ui/core";

// import { shallowEqual, useDispatch, useSelector } from "react-redux";

export function SendQuote({
  children,
  state,
  cardDetail,
  setOpenCanvaQuote,
  openCanvaQuote,
}) {
  const intl = useIntl();
  const { currentState, admin } = useSelector(
    (state) => ({ currentState: state.contact.agent, admin: state.auth.user }),
    shallowEqual
  );

  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);
  const [taskOpen, setTaskOpen] = useState(false);

  const ref = useRef();
  let checkLatestDeal =
    (cardDetail &&
      cardDetail.length &&
      cardDetail[0].contact &&
      cardDetail[0].contact.latest_deals?.id) ||
    "";
  const initialValues = {
    to: (cardDetail && cardDetail.length && cardDetail[0].email) || "",
    send: "easyStorage your quote",
    notes: "",
    contact_id:
      (cardDetail &&
        cardDetail.length &&
        cardDetail[0].contact &&
        cardDetail[0].contact.id) ||
      "",
    task_type: "",
    task_name: "",
    task_owner_id: admin && admin.id ? admin.id : "",
    task_description: "",
    due_date: "",
    is_completed: 0,
    deal_id: checkLatestDeal,
    priority: "",
    time: "",
  };
  const [timer, setTimer] = useState(false);

  const handleScroll = useCallback(
    (e) => {
      setTimer(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timer]
  );
  useEffect(() => {
    if (timer) {
      document
        .getElementById("kt_quick_panel_logs_kt_sendquote_panel")
        .addEventListener("scroll", handleScroll);
    } else {
      document
        .getElementById("kt_quick_panel_logs_kt_sendquote_panel")
        .removeEventListener("scroll", handleScroll);
    }

    return () => {
      document
        .getElementById("kt_quick_panel_logs_kt_sendquote_panel")
        .removeEventListener("scroll", handleScroll);
    };
  }, [timer, handleScroll]);

  const handleSetDate = (e) => {
    const formated = moment(e).format("YYYY-MM-DD");
    formik.setFieldValue("due_date", formated);
  };
  const handleSubmit = (values, actions) => {
    setLoading(true);
    setSnack({ call: false, type: "", message: "" });

    const taskValues = {
      contact_id: values?.contact_id || "",
      task_type: values?.task_type || "",
      task_name: values?.task_name || "",
      task_owner_id: values?.task_owner_id || "",
      task_description: values?.task_description || "",
      due_date: values?.due_date || "",
      is_completed: values?.is_completed,
      deal_id: values?.deal_id || "",
      priority: values?.priority || "",
      time: values?.time || "",
    };
    if (taskOpen) {
      createTask(taskValues)
        .then((res) => {
          // dispatch(fetchTasks(initialFilter));
          // formik.resetForm();
          setLoading(true);
          const data = handleQouteSubmitApi(values, actions, state);
          sendQuote(data)
            .then((res) => {
              setSnack({
                call: true,
                type: "success",
                message: res.data.message,
              });
              setTimeout(() => {
                setOpenCanvaQuote({ type: "", open: false });
              }, 3000);
              setLoading(false);
              // canvaCancel("kt_sendquote_panel_close");
            })
            .catch((error) => {
              setLoading(false);
              setSnack({
                call: true,
                type: "error",
                message: error?.response?.data?.message,
                // error: error?.response?.data?.errors,
              });
            });
          // setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setSnack({
            call: true,
            type: "error",
            message: error.response.data.message,
          });
        });
    } else {
      const data = handleQouteSubmitApi(values, actions, state);
      sendQuote(data)
        .then((res) => {
          setSnack({
            call: true,
            type: "success",
            message: res.data.message,
          });
          setTimeout(() => {
            setOpenCanvaQuote({ type: "", open: false });
          }, 3000);
          setLoading(false);
          // canvaCancel("kt_sendquote_panel_close");
        })
        .catch((error) => {
          setLoading(false);
          setSnack({
            call: true,
            type: "error",
            message: error?.response?.data?.message,
            // error: error?.response?.data?.errors,
          });
        });
    }
  };
  const handleReset = () => {};
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const handleQouteSubmitApi = (values, actions) => {
    const data = {
      to: values.to,
      subject: values.send,
      notes: values.notes,
      zip:
        cardDetail && cardDetail.length && cardDetail[0].postcode
          ? cardDetail[0].postcode
          : "",
      quote_type: openCanvaQuote.type === "draft" ? "draft" : "quote",
      email:
        cardDetail && cardDetail.length && cardDetail[0].email
          ? cardDetail[0].email
          : "",
      phone:
        cardDetail && cardDetail.length && cardDetail[0].phone
          ? cardDetail[0].phone
          : "",
      contact_id:
        cardDetail &&
        cardDetail.length &&
        cardDetail[0].contact &&
        cardDetail[0].contact?.id
          ? cardDetail[0].contact?.id
          : "",
      first_name:
        cardDetail && cardDetail.length && cardDetail[0].first_name
          ? cardDetail[0].first_name
          : "",
      last_name:
        cardDetail && cardDetail.length && cardDetail[0].last_name
          ? cardDetail[0].last_name
          : "",
      city: "",
      is_student: state.collectionRetrun?.is_student || "0",
      is_student_return: state.collectionRetrun?.is_student_return || "0",
      address:
        cardDetail && cardDetail.length && cardDetail[0].address
          ? cardDetail[0].address
          : "",
      deal_id:
        cardDetail &&
        cardDetail.length &&
        cardDetail[0].contact &&
        cardDetail[0].contact.latest_deals
          ? cardDetail[0].contact.latest_deals?.id
          : "",
      country: "GB",
      agent_id:
        cardDetail &&
        cardDetail.length &&
        cardDetail[0].contact &&
        cardDetail[0].contact?.agent?.id
          ? cardDetail[0].contact?.agent?.id
          : "",

      currency_code: "GBP",
      // pod_count: null,

      // driver_notes: state?.driver_note,
      container_size: state?.containerListSelect || "",
      site_id: state?.siteSelect?.id || "",
      ...state,
      collectionReturn: state.collectionRetrun,
      max_pod: state?.product_array.find((e) => e.type === "plan")?.quantity,
      is_container: state.containerTypeSelect === "container" ? 1 : 0,
    };
    // delete state.collectionRetrun;
    if (state.containerTypeSelect !== "container") {
      delete data.container_size;
      delete data.site_id;
    }
    return data;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: taskOpen ? SendQuoteSchema : "",
    onSubmit: handleSubmit,
    onReset: handleReset,
  });
  const handleChange = (event) => {
    setTaskOpen(event.target.checked);
  };
  return (
    <div
      id="kt_sendquote_panel"
      className="offcanvas offcanvas-right pt-5 pb-10 offcanvas-on"
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            onClick={() => setOpenCanvaQuote({ type: "", open: false })}
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_sendquote_panel_close"
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </span>
        </div>
      </div>
      {/*end::Header*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="offcanvas-content date-picker  px-10 ">
          <div>
            <h5>
              {openCanvaQuote.type === "draft" ? "Save Draft" : "Send Quote"}
            </h5>
          </div>
          <div className="tab-content">
            <div
              className="tab-pane fade pt-3 pr-5 mr-n5 custom-scroll  active show"
              id="kt_quick_panel_logs_kt_sendquote_panel"
              ref={ref}
              role="tabpanel"
            >
              {openCanvaQuote.type === "draft" ? (
                ""
              ) : (
                <>
                  <div className="mb-3">
                    <Form.Group className="mb-3">
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text id="inputGroupPrepend">
                            <strong>To</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <input
                          type="text"
                          placeholder="helpdesk@example.com"
                          aria-describedby="inputGroupPrepend"
                          className={`form-control  ${getInputClasses("to")}`}
                          {...formik.getFieldProps("to")}
                          name="to"
                        />
                      </InputGroup>
                      {formik.touched.to && formik.errors.to ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.to}
                          </div>
                        </div>
                      ) : null}
                    </Form.Group>
                    <Form.Group>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text id="inputGroupPrepend">
                            <strong>Subject</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          type="text"
                          placeholder="Your easyStorage quote"
                          aria-describedby="inputGroupPrepend"
                          className={`form-control  ${getInputClasses("send")}`}
                          {...formik.getFieldProps("send")}
                          name="send"
                        />
                      </InputGroup>
                      {formik.touched.send && formik.errors.send ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.send}
                          </div>
                        </div>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="mb-5"></div>
                  <div>
                    <h5>Order Summary</h5>
                    {children}
                  </div>
                  <div className="mb-5">
                    <label className="font-weight-bolder">Notes</label>
                    <div className="form-groupB">
                      <textarea
                        type="textarea"
                        name="notes"
                        className="form-control"
                        placeholder="notes"
                        label="Notes"
                        rows="4"
                        {...formik.getFieldProps("notes")}
                      />
                      {formik.touched.notes && formik.errors.notes ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.notes}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </>
              )}
              <div
                style={{ background: "rgb(243 246 249)", padding: "19px 8px" }}
              >
                <div>
                  <h5>
                    {intl.formatMessage({ id: "Create.Task" })}{" "}
                    <Switch
                      checked={taskOpen}
                      onChange={handleChange}
                      color="primary"
                      name="checkedB"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </h5>
                </div>
                <div
                  className={!taskOpen ? "disableDiv row mt-10" : "row mt-10"}
                >
                  <div className="col-md-4 mb-5">
                    <FormControl fullWidth>
                      <label>{intl.formatMessage({ id: "Task.Type" })}</label>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        inputProps={{ "aria-label": "Without label" }}
                        displayEmpty
                        name="task_type"
                        className={`form-control w-100  ${getInputClasses(
                          formik,
                          "task_type"
                        )}`}
                        {...formik.getFieldProps("task_type")}
                      >
                        <MenuItem value="">
                          {intl.formatMessage({ id: "Select" })}
                        </MenuItem>
                        <MenuItem value="Call">
                          {" "}
                          {intl.formatMessage({ id: "Call" })}
                        </MenuItem>
                        <MenuItem value="Email">
                          {intl.formatMessage({ id: "Email" })}
                        </MenuItem>
                        <MenuItem value="SMS">
                          {intl.formatMessage({ id: "SMS" })}
                        </MenuItem>
                      </Select>
                    </FormControl>
                    {formik.touched.task_type && formik.errors.task_type ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.task_type}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className="col-md-4 mb-5">
                    <FormControl fullWidth>
                      <label>{intl.formatMessage({ id: "Priority" })}</label>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        inputProps={{ "aria-label": "Without label" }}
                        displayEmpty
                        name="priority"
                        className={`form-control w-100  ${getInputClasses(
                          formik,
                          "priority"
                        )}`}
                        {...formik.getFieldProps("priority")}
                      >
                        <MenuItem value="">
                          {intl.formatMessage({ id: "Select" })}
                        </MenuItem>
                        <MenuItem value="Hot">
                          {intl.formatMessage({ id: "Hot" })}
                        </MenuItem>
                        <MenuItem value="Medium">
                          {intl.formatMessage({ id: "Medium" })}
                        </MenuItem>
                        <MenuItem value="Low">
                          {intl.formatMessage({ id: "Low" })}{" "}
                        </MenuItem>
                      </Select>
                    </FormControl>
                    {formik.touched.priority && formik.errors.priority ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.priority}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-4 mb-5">
                    <label>{intl.formatMessage({ id: "Task.Name" })}</label>
                    <input
                      type="text"
                      name="task_name"
                      className={`form-control  ${getInputClasses(
                        formik,
                        "task_name"
                      )}`}
                      placeholder={intl.formatMessage({ id: "Task.Name" })}
                      {...formik.getFieldProps("task_name")}
                    />
                    {formik.touched.task_name && formik.errors.task_name ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.task_name}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-4 mb-5">
                    <FormControl fullWidth>
                      <label>{intl.formatMessage({ id: "Task.Owner" })}</label>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        inputProps={{ "aria-label": "Without label" }}
                        displayEmpty
                        name="task_owner_id"
                        className={`form-control w-100  ${getInputClasses(
                          formik,
                          "task_owner_id"
                        )}`}
                        {...formik.getFieldProps("task_owner_id")}
                      >
                        <MenuItem value="">
                          {intl.formatMessage({ id: "Select" })}
                        </MenuItem>
                        <MenuItem value="is_unassigned">
                          {intl.formatMessage({ id: "Unassigned" })}
                        </MenuItem>
                        <MenuItem value={admin.id}>
                          {admin && admin.first_name ? admin.first_name : " "}
                          &nbsp;
                          {admin && admin.last_name ? admin.last_name : " "}
                        </MenuItem>
                        {currentState &&
                          currentState.map((data, index) => (
                            <MenuItem
                              key={index}
                              value={data.id}
                              disabled={data?.is_available !== 1 ? true : false}
                            >
                              {data && data.first_name ? data.first_name : " "}
                              &nbsp;
                              {data && data.last_name ? data.last_name : " "}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    {formik.touched.task_owner_id &&
                    formik.errors.task_owner_id ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.task_owner_id}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-4 mb-5">
                    <label>{intl.formatMessage({ id: "Time" })}</label>
                    <div>
                      <TimePicker
                        defaultValue={moment()}
                        value={moment(
                          `${moment().format("yyyy-MM-DD")} ${
                            formik.values.time === ""
                              ? "00:00"
                              : formik.values.time
                          }`
                        )}
                        showSecond={false}
                        open={timer}
                        onOpen={() => setTimer(true)}
                        onClose={() => setTimer(false)}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "time",
                            moment(e).format("HH:mm") === "Invalid date"
                              ? "00:00"
                              : moment(e).format("HH:mm")
                          )
                        }
                        focusOnOpen
                        clearIcon
                      />
                    </div>

                    {formik.touched.time && formik.errors.time ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.time}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-4">
                    <div className="form-groupB">
                      <label>{intl.formatMessage({ id: "Due.Date" })}</label>
                      <InputGroup className="calendr">
                        <DatePicker
                          fullWidth
                          fixedHeight
                          className="form-control w-100"
                          wrapperClassName="datepicker"
                          dateFormat="dd-MM-yyyy"
                          minDate={new Date()}
                          selected={Date.parse(formik.values?.due_date)}
                          onChange={(date) => handleSetDate(date)}
                          placeholderText="DD-MM-YYYY"
                        />
                        <CalendarTodayIcon />
                      </InputGroup>
                    </div>
                    {formik.touched.due_date && formik.errors.due_date ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.due_date}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className="col-md-12 mb-5">
                    <label htmlFor="exampleFormControlTextarea1">
                      {intl.formatMessage({ id: "Task.Description" })}
                    </label>
                    <textarea
                      style={{ width: "100%" }}
                      id="exampleFormControlTextarea1"
                      rows="3"
                      name="task_description"
                      className={`form-control  ${getInputClasses(
                        formik,
                        "task_description"
                      )}`}
                      {...formik.getFieldProps("task_description")}
                    ></textarea>
                    {formik.touched.task_description &&
                    formik.errors.task_description ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.task_description}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-12 mb-5">
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        name="Checkboxes4"
                        onChange={(e) =>
                          formik.setFieldValue(
                            "is_completed",
                            e.target.checked ? 1 : 0
                          )
                        }
                      />
                      <span className="mr-2"></span>
                      {intl.formatMessage({ id: "Completed" })}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="reset"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() => setOpenCanvaQuote({ type: "", open: false })}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
              disabled={loading}
            >
              {openCanvaQuote.type === "draft" ? "Save Draft" : "Send Quote"}
              {loading && (
                <span className="ml-2 mr-2 spinner spinner-white"></span>
              )}
            </button>
          </div>
        </div>
      </form>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
}
