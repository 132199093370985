/* eslint-disable no-restricted-imports */
/* eslint-disable no-unused-vars */
import React from "react";
import NewBreadCrumbs from "../../../../_metronic/layout/components/subheader/components/NewBreadCrumbs";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  fetchEditOrderDetailCard,
  fetchEditOrderSummary,
  fetchOrderSummaryListStripe,
} from "../_redux/order/orderAction";
import { useEffect } from "react";
import { ApplyChangesCard } from "../components/EditOrderComponents/ApplyChangesCard";
import { useOrderUIContext } from "../OrderUIContext";
import { useState } from "react";
import UpdateSubscriptionModal from "../components/EditOrderComponents/Modal/UpdateSubscriptionModal";
import { useHistory } from "react-router-dom";
import { EditOrderList } from "../components/EditOrderComponents/EditOrderList";
import { EditBreakDownCard } from "../components/EditOrderComponents/EditBreakDownCard";
import { Table } from "react-bootstrap";
import { useContextState } from "../reducer/editOrderStripeReducer";
import moment from "moment";
import {
  Switch,
  FormGroup,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  List,
  ListItem,
} from "@material-ui/core";
import { deleteStateData, planProductId } from "../_mocks/dummyData";
import _ from "lodash";
import { editStripeOrder } from "../_redux/order/orderCrud";
import {
  UpdateData,
  removeCouponFromPhases,
  resetDiscounts,
  transformData,
} from "./editStripeUtils.js";
import { makeStyles } from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ImageIcon from "@material-ui/icons/Image";
import WorkIcon from "@material-ui/icons/Work";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import { LoadingSpinner } from "../../../../_metronic/_partials/controls/LoadingSpinner.js";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar.js";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { LoadingCustome } from "../../../../_metronic/_partials/controls/LoadingCustome.js";
import AddProductModal from "../components/EditOrderComponents/Modal/AddProoductModal.js";
import AddProductMainModal from "../components/EditOrderComponents/Modal/AddProductMainModal.js";

export const EditOrderStripe = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  const { state, dispatchReducer } = useContextState();
  const history = useHistory();
  const [dialog, setDialog] = useState({ update: false });
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [updateLoading, setUploadLoading] = useState(false);
  const orderUIContext = useOrderUIContext();
  const {
    editOrderDetail,
    editOrderSummary,
    subLoading,
    callApi,
    orderSummaryListStripe,
  } = useSelector(
    (state) => ({
      editOrderDetail: state.order.editOrderDetail,
      editOrderSummary: state.order.editOrderSummary,
      subLoading: state.order.sub,
      callApi: state.order.callApi,
      orderSummaryListStripe: state.order.orderSummaryListStripe,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchEditOrderDetailCard(props?.match?.params?.id));
  }, [dispatch, props.match.params.id]);

  useEffect(() => {
    if (editOrderDetail?.subscription_data) {
      const insurance_cover = editOrderDetail?.order_summary?.find(
        (e) =>
          e?.stripe_product_id === process.env.REACT_APP_PRODUCT_POD_PROTECT
      );
      dispatchReducer({
        type: "SET_DATA",
        data: {
          ...state,
          ...editOrderDetail?.subscription_data,
          proration_behavior: "none",
          insurance_cover: insurance_cover?.cover,
        },
      });
    }
  }, [editOrderDetail?.subscription_data]);

  const [value, setValue] = React.useState("female");

  const handleChange = (event) => {
    const phaseArr = [...state.phases];
    const phase = phaseArr.find((e) => e.is_edit);
    phase.proration_behavior = event.target.value;
    dispatchReducer({
      type: "SET_DATA",
      data: {
        ...state,
        proration_behavior: event.target.value,
        phases: phaseArr,
        is_call_upcoming: true,
      },
    });
    setValue(event.target.value);
  };

  const handleUpdateOrder = () => {
    setUploadLoading(true);
    setSnack({ call: false, type: "", message: "" });
    const phase = state.phases.find((e) => e.is_edit);
    const podQty = phase.items.find((item) =>
      planProductId.includes(item.product_id)
    )?.quantity;
    const cleanedData = UpdateData(state, props?.match?.params?.id, podQty);
    const copyCleanedData = JSON.parse(JSON.stringify(cleanedData));
    copyCleanedData.schedule_data.phases.map((phase, index) => {
      phase.discounts = state.phases[index].discounts.map((discount) => {
        const { discount: _, promotion_code: __, ...rest } = discount;
        return rest;
      });
    });

    //
    // const phasePro = state?.phases?.find((e) => e.is_edit);
    // const phaseProIndex = state?.phases?.findIndex((e) => e.is_edit);

    // const planPr = phasePro?.items?.filter((item) => {
    //   const match = orderSummaryListStripe?.allProducts?.find(
    //     (e) => e?.stripe_product_id == item.product_id
    //   );
    //   return match?.type === "plan";
    // });
    // const phaseProOld =
    //   editOrderDetail?.subscription_data?.phases[phaseProIndex];

    // const planPrOld = phaseProOld?.items?.filter((item) => {
    //   const match = orderSummaryListStripe?.allProducts?.find(
    //     (e) => e?.stripe_product_id == item.product_id
    //   );
    //   return match?.type === "plan";
    // });
    // copyCleanedData.planOldQuantity = planPr[0]?.quantity;
    // copyCleanedData.planNewQuantity = planPrOld[0]?.quantity;

    editStripeOrder(copyCleanedData)
      .then((res) => {
        setUploadLoading(false);

        // history.push(`/order-details/${props?.match?.params?.id}`);
        setSnack({
          call: true,
          type: "success",
          message: "Subscription Added Succesfully ",
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        setUploadLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: error?.response?.data?.message,
        });
      });
  };

  const handleUpcomingApi = () => {
    const cleanedData = UpdateData(state, props?.match?.params?.id);
    cleanedData.customer = cleanedData.customer_id;
    const copyCleanedData = JSON.parse(JSON.stringify(cleanedData));
    copyCleanedData.schedule_data.phases.map((phase, index) => {
      phase.discounts = state.phases[index].discounts.map((discount) => {
        const { discount: _, promotion_code: __, ...rest } = discount;
        return rest;
      });
    });

    copyCleanedData.customer = copyCleanedData.customer_id;
    // const gog = resetDiscounts(copyCleanedData);
    dispatch(fetchEditOrderSummary(copyCleanedData));
  };
  const [callUpComing, setCallUpComing] = useState(false);
  useEffect(() => {
    if (state?.is_call_upcoming) {
      handleUpcomingApi();
      dispatchReducer({
        type: "SET_DATA",
        data: {
          ...state,
          is_call_upcoming: false,
        },
      });
    }
  }, [state?.is_call_upcoming]);

  return (
    <div>
      {/* {editOrderDetail?.id ? "" : <LoadingCustome />} */}
      <NewBreadCrumbs breadcrumb={true} title={"Edit Order"} />
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          fontSize: "16px",
          marginBottom: "2%",
          color: "red",
        }}
      >
        {state?.phases?.some((e) => e.is_edit) ? (
          ""
        ) : (
          <span>
            When edit any phase, you also need to edit the subsequent phases to
            maintain consistency.
          </span>
        )}
      </div>
      <div className="row">
        {state?.phases?.some((e) => e.is_edit) && (
          <div className="col-md-12">
            <div className="text-right mb-2">
              <button
                type="button"
                class="btn btn-primaryCus font-weight-bolder font-size-sm mr-3 "
                onClick={() => {
                  const updatedArray = state.phases
                    // Filter out the objects that have `is_add_phase` set to true
                    .filter((item) => !item.is_add_phase)
                    // Map over the filtered array to set `is_edit` to false if it's true
                    .map((item) => ({
                      ...item,
                      is_edit: item.is_edit ? false : item.is_edit,
                    }));
                  dispatchReducer({
                    type: "SET_DATA",
                    data: {
                      ...state,
                      phases: updatedArray,
                    },
                  });
                }}
              >
                Dismiss
              </button>
              {updateLoading ? (
                <LoadingCustome />
              ) : (
                <button
                  type="button"
                  class="btn btn-primaryCus font-weight-bolder font-size-sm mr-3 "
                  onClick={handleUpdateOrder}
                >
                  Update
                </button>
              )}
            </div>
          </div>
        )}
        {!state?.phases?.some((e) => e.is_edit) && (
          <div className="col-md-12">
            <div className="card card-custom gutter-b">
              <div className="card-body pt-0">
                <div
                  className="py-4"
                  style={{
                    marginBottom: "-24px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h3 className="card-title align-items-start flex-column">
                    <span className="font-weight-bolder text-dark">
                      Subscription detail
                    </span>
                  </h3>
                  <div style={{ display: "flex", width: "20%" }}>
                    {/* <button
                      type="button"
                      class="btn btn-primaryCus font-weight-bolder font-size-sm mr-3 w-100 "
                      style={{ cursor: "pointer", height: "40px" }}
                      onClick={() =>
                        setDialog({
                          check: true,
                          title: "Add Charge",
                          type: "charge",
                        })
                      }
                    >
                      Add Charge
                    </button>
                    <button
                      type="button"
                      class="btn btn-primaryCus font-weight-bolder font-size-sm mr-3 w-100 "
                      style={{ cursor: "pointer", height: "40px" }}
                      onClick={() =>
                        setDialog({
                          check: true,
                          title: "Add Add-on",
                          type: "plan",
                        })
                      }
                    >
                      Add Add-on
                    </button> */}
                  </div>
                </div>
                {!state?.phases && <LoadingSpinner />}
                {state?.phases?.length > 0 &&
                  state?.phases.map((e, i) => {
                    const productText = e.items
                      .map((item) => `${item.product_name} x ${item.quantity}`)
                      .join(", ");
                    return (
                      <>
                        <div
                          className="d-flex w-100"
                          style={{ justifyContent: "space-between" }}
                          key={i}
                        >
                          <div>
                            <div>
                              <span style={{ fontSize: "16px" }}>
                                <strong>
                                  {moment(e.start_date * 1000).format(
                                    "MMMM D, YYYY"
                                  ) +
                                    "-" +
                                    moment(e.end_date * 1000).format(
                                      "MMMM D, YYYY"
                                    )}
                                </strong>
                              </span>
                            </div>
                            <div>
                              <span style={{ fontSize: "12px" }}>
                                <>{productText}</>
                              </span>
                            </div>
                          </div>
                          <div style={{ display: "flex" }}>
                            {state?.phases?.length > 2 && i == 0 ? (
                              ""
                            ) : (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  const phases = [...state.phases];
                                  const updatedPhase = {
                                    ...phases[i],
                                    is_edit: true,
                                  };
                                  // Replace the old phase object with the updated one
                                  phases[i] = updatedPhase;
                                  dispatchReducer({
                                    type: "SET_DATA",
                                    data: {
                                      ...state,
                                      phases,
                                    },
                                  });

                                  handleUpcomingApi();
                                  dispatch(
                                    fetchOrderSummaryListStripe({
                                      franchise_id:
                                        editOrderDetail?.franchise_id,
                                      postcode: editOrderDetail?.postcode,
                                    })
                                  );
                                }}
                              >
                                Edit
                              </div>
                            )}
                          </div>
                        </div>
                        <hr />
                      </>
                    );
                  })}
                <div>
                  <button
                    type="button"
                    class="btn btn-primaryCus font-weight-bolder font-size-sm mr-3 w-100 "
                    onClick={() => {
                      const phases = [...state.phases];
                      phases.push(phases[phases.length - 1]);
                      const updatedPhase = {
                        ...phases[phases.length - 1],
                        is_add_phase: true,
                        is_edit: true,
                      };
                      // Replace the old phase object with the updated one
                      phases[phases.length - 1] = updatedPhase;
                      updatedPhase.start_date =
                        phases[phases.length - 1].end_date;
                      updatedPhase.end_date = null;

                      // Replace the old phase object with the updated one

                      dispatchReducer({
                        type: "SET_DATA",
                        data: {
                          ...state,
                          phases,
                        },
                      });
                    }}
                  >
                    + Add Phase
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {state?.phases?.some((e) => e.is_edit) && (
          <>
            <div className="col-md-8">
              <div className="card card-custom gutter-b">
                <EditOrderList
                  state={state}
                  setCallUpComing={setCallUpComing}
                  dispatchReducer={dispatchReducer}
                  phases={state.phases?.find((e) => e?.is_edit)}
                  editOrderDetail={editOrderDetail}
                  orderUIContext={orderUIContext}
                  orderSummaryListStripe={orderSummaryListStripe}
                />
                {state.status === "not_started" &&
                state?.phases?.findIndex((e) => e.is_edit) == 0 ? (
                  ""
                ) : (
                  <div className="card-body pt-0">
                    {editOrderDetail?.subscription_data?.status ===
                      "active" && (
                      <div className="py-4">
                        <div>
                          <h3 className="card-title align-items-start flex-column">
                            <span className="font-weight-bolder text-dark">
                              Proration behavior
                            </span>
                          </h3>
                        </div>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={state?.proration_behavior !== "none"}
                                onChange={(e) => {
                                  dispatchReducer({
                                    type: "SET_DATA",
                                    data: {
                                      ...state,
                                      proration_behavior:
                                        state?.proration_behavior === "none"
                                          ? ""
                                          : "none",
                                    },
                                  });
                                }}
                                name="checkedB"
                                color="primary"
                              />
                            }
                            label="Prorate changes"
                          />
                        </FormGroup>
                        {state?.proration_behavior !== "none" ? (
                          <FormControl component="fieldset">
                            <RadioGroup
                              aria-label="gender"
                              name="gender1"
                              value={state?.proration_behavior}
                              onChange={handleChange}
                            >
                              <FormControlLabel
                                value="create_prorations"
                                control={<Radio />}
                                label="Charge proration amount on next invoice"
                              />
                              <FormControlLabel
                                value="always_invoice"
                                control={<Radio />}
                                label="Charge proration amount immediately"
                              />
                            </RadioGroup>
                          </FormControl>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="card card-custom gutter-b">
                <div className="card-body pt-0">
                  <div className="py-4" style={{ marginBottom: "-24px" }}>
                    <h3 className="card-title align-items-start flex-column">
                      <span className="font-weight-bolder text-dark">
                        Summary
                      </span>
                    </h3>
                  </div>
                  {subLoading && <LoadingSpinner />}
                  {editOrderSummary.period_start && (
                    <List className={classes.root}>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <CalendarTodayIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={moment(
                            editOrderSummary &&
                              editOrderSummary.period_start &&
                              editOrderSummary.period_start * 1000
                          ).format("MMMM D, YYYY")}
                          secondary="Subscription updates"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <ReceiptIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary="Next Invoice"
                          secondary={`Amount Due £${
                            editOrderSummary && editOrderSummary.amount_due
                              ? Number(editOrderSummary.amount_due) / 100
                              : ""
                          }`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <ReceiptIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary="Billing"
                          secondary={`Bills on ${
                            editOrderSummary?.created
                              ? moment(editOrderSummary?.created * 1000).format(
                                  "DD-MM-YYYY"
                                )
                              : ""
                          }`}
                        />
                      </ListItem>
                    </List>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        <div className="col-md-4">
          {Object.keys(orderUIContext?.state?.breakdown).length > 0 && (
            <div className="card card-custom gutter-b">
              <EditBreakDownCard orderUIContext={orderUIContext} />
            </div>
          )}
        </div>
        {orderUIContext?.state?.orderData?.status === "active" && (
          <div className="col-md-8">
            <div className="card card-custom gutter-b">
              <ApplyChangesCard orderUIContext={orderUIContext} />
            </div>
          </div>
        )}
      </div>
      {dialog.update && (
        <UpdateSubscriptionModal
          dialog={dialog}
          setDialog={setDialog}
          orderUIContext={orderUIContext}
        />
      )}
      {dialog?.check && (
        <AddProductMainModal
          dialog={dialog}
          setDialog={setDialog}
          state={state}
          dispatchReducer={dispatchReducer}
        />
      )}
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
};
