import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  FormControl,
  MenuItem,
  Select,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { InputGroup } from "react-bootstrap";
import { OrderSummaryTable } from "./OrderSummaryTable";
import { PostcodeSearch } from "./PostcodeSearch";
import { OrderSummaryButton } from "./OrderSummaryButton";
import {
  isItemExists,
  multiplyCostByNumber,
  transformDataToAddProduct,
} from "../NewOrderStripeUtils";
import { chargebee } from "../../_mocks/dummyData";
import {
  billingCycleCoupons,
  getfranchiseType,
} from "../../_redux/order/orderCrud";
import { planChangeChargeReset } from "../../orderUtils";
import { SendQuote } from "../../order-canva/SendQuote";
import { shallowEqual, useSelector } from "react-redux";

export const OrderSummary = ({
  state,
  dispatchReducer,
  orderSummaryListStripe,
  cardDetail,
}) => {
  const { role } = useSelector(
    (state) => ({
      role: state.auth.user.roles,
    }),
    shallowEqual
  );

  const intl = useIntl();
  const [callBillingApi, setCallBillingApi] = useState(false);
  const [productValue, setProductValue] = useState(null);
  const [sendQuoteCanva, setSendQuoteCanva] = useState({ open: true });
  const [callBillingType, setCallBillingType] = useState(false);

  const handleBillingCycle = (value, sameTime, call) => {
    const array = multiplyCostByNumber(state.product_array, value, state);
    console.log("item-90", array);
    const billingParam = { period: "month", unit: value };
    billingCycleCoupons({
      id: state?.franchise_id,
      cycle: value + " " + state?.billingParam.period,
      period: state?.billingParam.period,
      is_container: state.containerTypeSelect === "container" ? 1 : 0,
      is_same_time_return: sameTime ? 1 : 0,
    })
      .then((res) => {
        const default_coupon = res.data.data;
        dispatchReducer({
          type: "SET_DATA",
          data: {
            ...state,
            product_array: array,
            billingParam,
            default_coupon,
            set: 2,
            extraMilesCall: call === "call" ? true : false,
          },
        });
      })
      .catch((error) => {
        dispatchReducer({
          type: "SET_DATA",
          data: { ...state, product_array: array, billingParam, set: 3 },
        });
      });
  };

  useEffect(() => {
    if (state.collectionRetrun?.return_date) {
      handleBillingCycle(1, 1, "call");
    }
  }, [state.collectionRetrun?.return_date]);

  useEffect(() => {
    if (state.collectionRetrun?.return_date) {
      const conge =
        orderSummaryListStripe?.pod?.charges?.return_congestion_charge?.length >
        0
          ? orderSummaryListStripe?.pod?.charges?.return_congestion_charge[0]
          : "";
      const arr = [...state?.product_array];
      const check = arr.find(
        (e) => e.price_id === process.env.REACT_APP_PRODUCT_COL_CON_CHARGE
      );
      if (conge?.id && !check) {
        const data = transformDataToAddProduct(conge);
        dispatchReducer({
          type: "SET_DATA",
          data: { ...state, product_array: [...state.product_array, data] },
        });
      }
    }
  }, [
    state.collectionRetrun?.return_date,
    orderSummaryListStripe?.pod?.charges?.return_congestion_charge?.length,
  ]);

  useEffect(() => {
    if (
      state?.product_array?.length > 0 &&
      !callBillingApi &&
      state?.franchise_id
    ) {
      billingCycleCoupons({
        id: state?.franchise_id,
        cycle: state?.billingParam.unit + " " + state?.billingParam.period,
        period: state?.billingParam.period,
        is_container: state.containerTypeSelect === "container" ? 1 : 0,
      }).then((res) => {
        const default_coupon = res.data.data;

        dispatchReducer({
          type: "SET_DATA",
          data: { ...state, default_coupon },
        });
        setCallBillingApi(true);
      });
    }
  }, [state?.product_array?.length, state?.franchise_id]);

  const handleChangePlan = (value) => {
    const arr = [...state.product_array];
    const planType = orderSummaryListStripe?.pod?.plans.find(
      (e) => e.stripe_product_id === value.target.value
    );
    const plan = transformDataToAddProduct(planType);
    plan.total = +plan.total * +state.billingParam.unit;
    const con = arr.find(
      (e) =>
        e.price_id === process.env.REACT_APP_PRODUCT_COLLECTION_SERVICE ||
        e.price_id === process.env.REACT_APP_PRODUCT_COL_PUB_HOLIDAY ||
        e.price_id === process.env.REACT_APP_PRODUCT_WEEKEND_CHARGE ||
        e.price_id === process.env.REACT_APP_PRODUCT_RET_SER ||
        e.price_id === process.env.REACT_APP_PRODUCT_RET_PUB_HOLIDAY ||
        e.price_id === process.env.REACT_APP_PRODUCT_RET_WEEKEND_CHARGE
    );
    const conIndex = arr.findIndex(
      (e) =>
        e.price_id === process.env.REACT_APP_PRODUCT_COLLECTION_SERVICE ||
        e.price_id === process.env.REACT_APP_PRODUCT_COL_PUB_HOLIDAY ||
        e.price_id === process.env.REACT_APP_PRODUCT_WEEKEND_CHARGE ||
        e.price_id === process.env.REACT_APP_PRODUCT_RET_SER ||
        e.price_id === process.env.REACT_APP_PRODUCT_RET_PUB_HOLIDAY ||
        e.price_id === process.env.REACT_APP_PRODUCT_RET_WEEKEND_CHARGE
    );
    if (con?.price_id) {
      const newCon = planChangeChargeReset(con, 1);
      arr[conIndex] = newCon;
    }
    arr.shift();
    arr.unshift(plan);
    dispatchReducer({
      type: "SET_DATA",
      data: { ...state, product_array: arr },
    });
  };

  const handleContainerAccessPlanChange = (q) => {
    const arr = [...state.product_array];
    const index = arr.findIndex(
      (e) =>
        e.price_id === process.env.REACT_APP_PRODUCT_STANDARD_ACCESS ||
        e.price_id === process.env.REACT_APP_PRODUCT_EXTENDED_ACCESS ||
        e.price_id === process.env.REACT_APP_PRODUCT_24HR_ACCESS
    );
    arr.splice(index, 1);
    const data = transformDataToAddProduct(q);
    arr.push(data);
    const addUniqueProducts = isItemExists(state.product_array, data);
    if (!addUniqueProducts) {
      dispatchReducer({
        type: "SET_DATA",
        data: { ...state, product_array: arr },
      });
    }
  };
  const handleChangeType = (e) => {
    dispatchReducer({
      type: "SET_DATA",
      data: {
        ...state,
        type_change: true,
        defaultProduct: true,
        containerTypeSelect: e.target.value,
        containerList:
          e.target.value === "container"
            ? [
                {
                  id: "20ft Non-Ele",
                  type: "NON",
                },
              ]
            : "",
        containerListSelect: "20ft Non-Ele",
        collectionRetrun: {},
      },
    });
    setCallBillingType(true);
  };
  useEffect(() => {
    if (callBillingType && !state.type_change) {
      handleBillingCycle(state?.billingParam.unit);
      setCallBillingType(false);
    }
  }, [callBillingType, state.type_change]);

  const [planType, setPlanType] = useState([]);
  useEffect(() => {
    if (state?.franchise_id) {
      getfranchiseType(state?.franchise_id).then((res) => {
        console.log("hello-90", res);
        setPlanType(res.data.data?.plan_type);
      });
    }
  }, [state?.franchise_id]);

  console.log("state-80", state);
  return (
    <>
      <>
        <div
          className="card card-custom gutter-b order-summary"
          style={{ minHeight: "640px" }}
        >
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div>
                {state.containerTypeSelect && (
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      defaultValue={state.containerTypeSelect}
                      value={state.containerTypeSelect}
                      onChange={handleChangeType}
                    >
                      {role && role?.length && role[0] === "super-admin" ? (
                        <>
                          {planType.some((e) => e === "pod") && (
                            <FormControlLabel
                              value="pod"
                              control={<Radio color="primary" />}
                              label="Pod"
                            />
                          )}
                          {planType.some((e) => e === "container") && (
                            <FormControlLabel
                              value="container"
                              control={<Radio color="primary" />}
                              label="Container"
                            />
                          )}
                          {/* <FormControlLabel
                            value="pod"
                            control={<Radio color="primary" />}
                            label="Pod"
                          />
                          <FormControlLabel
                            value="container"
                            control={<Radio color="primary" />}
                            label="Container"
                          /> */}
                        </>
                      ) : (
                        <>
                          {state.plan_type.some((e) => e === "pod") && (
                            <FormControlLabel
                              value="pod"
                              control={<Radio color="primary" />}
                              label="Pod"
                            />
                          )}
                          {state.plan_type.some((e) => e === "container") && (
                            <FormControlLabel
                              value="container"
                              control={<Radio color="primary" />}
                              label="Container"
                            />
                          )}
                        </>
                      )}
                    </RadioGroup>
                  </FormControl>
                )}
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1">
                    <div className="d-flex align-items-center justify-content-between flex-wrap mt-2">
                      <div className="mr-3 mb-5">
                        <h4 className="card-title align-items-start flex-column mb-0">
                          <span className="font-weight-bolder text-dark">
                            {intl.formatMessage({ id: "Order.summary" })}
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mr-1 w-30" style={{ width: "50%" }}>
                <div className="d-flex">
                  {!state?.collectionRetrun?.return_date && (
                    <FormControl fullWidth className="mr-2">
                      <Select
                        name="address"
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        className={`form-control 
                      
                        `}
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        inputProps={{ "aria-label": "Without label" }}
                        displayEmpty
                        value={
                          state?.billingParam?.period === "week"
                            ? "1w"
                            : state?.billingParam?.period === "year"
                            ? "12"
                            : state?.billingParam.unit
                        }
                        onChange={(e) => handleBillingCycle(e.target.value)}
                      >
                        {/* <MenuItem value=" ">
                                                {intl.formatMessage({ id: "Billing.Cycle" })}
                                            </MenuItem> */}
                        {chargebee &&
                          chargebee.length &&
                          chargebee.map((data, index) => (
                            <MenuItem
                              value={data.unit}
                              key={index}
                              disabled={
                                state?.productArray?.some(
                                  (e) => e.id === "Howdens"
                                )
                                  ? data.unit > 2 || data.unit === "1w"
                                  : data.unit === "1w" &&
                                    state.collectionRetrun?.return_date &&
                                    state.collectionRetrun
                                      ?.return_start_time_slot
                                  ? true
                                  : false
                              }
                            >
                              {data.title}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  )}
                </div>
              </div>
            </div>
            <div className="mb-5">
              <PostcodeSearch
                dispatchReducer={dispatchReducer}
                state={state}
                // quantity={quantity}
                // setProductArray={setProductArray}
                // setParamData={setParamData}
                // paramData={paramData}
                // cardDetail={cardDetail}
                // handleChangeOrder={handleChangeOrder}
              />
              <div className="d-flex prod-search  ">
                <div
                  className="order-search product-search"
                  style={{ width: "50% !important" }}
                >
                  {orderSummaryListStripe?.allProducts?.length > 0 && (
                    <InputGroup>
                      <Autocomplete
                        autoComplete={false}
                        fullWidth
                        loading={false}
                        filterSelectedOptions
                        onChange={(e, q) => {
                          if (
                            q.stripe_product_id ==
                              process.env.REACT_APP_PRODUCT_EXTENDED_ACCESS ||
                            q.stripe_product_id ==
                              process.env.REACT_APP_PRODUCT_STANDARD_ACCESS ||
                            q.stripe_product_id ==
                              process.env.REACT_APP_PRODUCT_24HR_ACCESS
                          ) {
                            handleContainerAccessPlanChange(q);
                            return;
                          }
                          const data = transformDataToAddProduct(q);
                          const arr = [...state.product_array, data];
                          const addUniqueProducts = isItemExists(
                            state.product_array,
                            data
                          );
                          if (!addUniqueProducts) {
                            if (q.type === "packaging_material") {
                              const shipProduct = orderSummaryListStripe.allProducts.find(
                                (e) =>
                                  e.stripe_product_id ===
                                  process.env.REACT_APP_PRODUCT_SHIPPING_FEE
                              );
                              const data = transformDataToAddProduct(
                                shipProduct
                              );
                              const addUniqueProducts = isItemExists(
                                state.product_array,
                                data
                              );
                              if (!addUniqueProducts) {
                                arr.push(data);
                              }
                              dispatchReducer({
                                type: "SET_DATA",
                                data: {
                                  ...state,
                                  product_array: arr,
                                  is_packaging_check: true,
                                },
                              });
                            } else {
                              if (
                                process.env.REACT_APP_PRODUCT_8BY8CONTAINER ===
                                q?.stripe_product_id
                              ) {
                                dispatchReducer({
                                  type: "SET_DATA",
                                  data: {
                                    ...state,
                                    containerListSelect: q.name,
                                    product_array: arr,
                                  },
                                });
                              } else {
                                dispatchReducer({
                                  type: "SET_DATA",
                                  data: { ...state, product_array: arr },
                                });
                              }
                            }
                          }
                        }}
                        onInputChange={(event, newInputValue) => {
                          setProductValue(newInputValue);
                        }}
                        inputValue={productValue || ""}
                        id="controllable-states-demo"
                        options={orderSummaryListStripe?.allProducts}
                        getOptionLabel={(option) =>
                          `${option?.id} ${" | "}${option?.name} `
                        }
                        renderOption={(props, option) => {
                          return (
                            <span>
                              {props?.name}|{props?.id}
                            </span>
                          );
                        }}
                        style={{ width: 270 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label={intl.formatMessage({ id: "Search.Product" })}
                            variant="outlined"
                            className="form-control"
                            // onChange={(e) => setSelectV(true)}
                          />
                        )}
                      />
                    </InputGroup>
                  )}
                </div>
                <div className="ml-3">
                  <button
                    className="btn btn-secondary font-weight-bolder font-size-sm mr-1 "
                    // onClick={() => handleStorage()}
                  >
                    {intl.formatMessage({ id: "Add/Edit.Storage.Items" })}
                  </button>
                </div>
              </div>
              <br />
              {/* {state?.containerTypeSelect === "pod" && !orderSummaryData.loading && */}
              {/* <FormControl component="fieldset">
                                <FormLabel component="legend">Select Plan</FormLabel>
                                <RadioGroup row aria-label="position" name="position" defaultValue="top" value={``}
                                //    onChange={handleChangePlan}
                                >

                                    <FormControlLabel value="Economy-POD" control={<Radio color="primary" />} label="Economy" />
                                    <FormControlLabel value="POD" control={<Radio color="primary" />} label="Premium" />
                                    <FormControlLabel value="First-Class" control={<Radio color="primary" />} label="First Class" />

                                </RadioGroup>
                            </FormControl> */}
              {/* } */}
              {state?.containerTypeSelect != "container" &&
              !state?.collectionRetrun?.return_date &&
              state?.product_array.length > 0 ? (
                <FormControl component="fieldset">
                  <FormLabel component="legend">Select Plan</FormLabel>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    defaultValue="top"
                    value={
                      state?.product_array.find((e) => e.type === "plan")
                        ?.price_id
                    }
                    onChange={handleChangePlan}
                  >
                    <FormControlLabel
                      value={process.env.REACT_APP_PRODUCT_ECONOMY}
                      control={<Radio color="primary" />}
                      label="Economy"
                    />
                    <FormControlLabel
                      value={process.env.REACT_APP_PRODUCT_PREMIUM}
                      control={<Radio color="primary" />}
                      label="Premium"
                    />
                    <FormControlLabel
                      value={process.env.REACT_APP_PRODUCT_FIRST_CLASS}
                      control={<Radio color="primary" />}
                      label="First Class"
                    />
                  </RadioGroup>
                </FormControl>
              ) : (
                ""
              )}
            </div>
            <div>
              <OrderSummaryTable
                state={state}
                dispatchReducer={dispatchReducer}
                // quantityUpdate={handleOrderTableQuantity}
                // rowDelete={handleOrderTableDelete}
                // orderData={orderSummaryData.data}
                // orderDataTwo={orderSummaryData?.data_two}
                // handleDelete={handleOrderTableDelete}
                // loading={orderSummaryData.loading}
                // setProductArray={setProductArray}
                // productArray={productArray}
                // setTotalData={setTotalData}
                // totalData={totalData}
                // dispatchReducer={dispatchReducer}
                // state={state}
                // setQuantity={setQuantity}
                // quantity={quantity}
                // setParamData={setParamData}
                // paramData={paramData}
                // location={location}
                // secCheck={secCheck}
                // handleBilling={handleBilling}
              />
            </div>
            {/* {(state?.productArray && state?.productArray.length) ||
              orderSummaryData.loading ? ( */}
            <>
              <OrderSummaryButton
                state={state}
                dispatchReducer={dispatchReducer}
                orderSummaryListStripe={orderSummaryListStripe}
                setSendQuoteCanva={setSendQuoteCanva}
                sendQuoteCanva={sendQuoteCanva}
                //   formik={formik}
                //   setOpenCanvaQuote={setOpenCanvaQuote}
                //   disableAll={disableAll}
                //   intl={intl}
                //   setOpenCanva={setOpenCanva}
                //   getInputClasses={getInputClasses}
                //   applyCode={applyCode}
                //   setApplyCode={setApplyCode}
                //   handleOfferCode={handleOfferCode}
                //   franchise={franchise}
                //   paramData={paramData}
                //   setParamData={setParamData}
                //   fetchOrderSummary={fetchOrderSummary}
                //   cardDetail={cardDetail}
                //   dispatch={dispatch}
                //   setSnack={setSnack}
                //   location={location}
              />
            </>
            {/* ) : (
              ""
            )} */}
          </div>
        </div>
        {/* <OrderNotes
          state={state}
          dispatchReducer={dispatchReducer}
          cardDetail={cardDetail}
        /> */}
        {/* ) : (
          ""
        )} */}
        {/* {snack.call ? <SnackBarTool {...snack} /> : ""}
        {openCanva ? ( */}
        <>
          {/* <ManualDiscount
            //   setOpenCanva={setOpenCanva}
            //   state={state}
            //   dispatchReducer={dispatchReducer}
            //   handleUpdateManualAllDiscount={handleUpdateManualAllDiscount}
            /> */}
          {/* <div
                        className="offcanvas-overlay"
                    //   onClick={() => setOpenCanva(false)}
                    ></div> */}
        </>
        {/* ) : (
          ""
        )} */}
        {(sendQuoteCanva.type === "quote" || sendQuoteCanva.type === "draft") &&
        sendQuoteCanva.open ? (
          <>
            <SendQuote
              state={state}
              cardDetail={cardDetail}
              setOpenCanvaQuote={setSendQuoteCanva}
              openCanvaQuote={sendQuoteCanva}
            >
              <div>
                <OrderSummaryTable
                  state={state}
                  dispatchReducer={dispatchReducer}

                  // quantityUpdate={handleOrderTableQuantity}
                  // rowDelete={handleOrderTableDelete}
                  // orderData={orderSummaryData.data}
                  // orderDataTwo={orderSummaryData?.data_two}
                  // handleDelete={handleOrderTableDelete}
                  // loading={orderSummaryData.loading}
                  // setProductArray={setProductArray}
                  // productArray={productArray}
                  // setTotalData={setTotalData}
                  // totalData={totalData}
                  // dispatchReducer={dispatchReducer}
                  // state={state}
                  // setQuantity={setQuantity}
                  // quantity={quantity}
                  // setParamData={setParamData}
                  // paramData={paramData}
                  // location={location}
                  // secCheck={secCheck}
                  // handleBilling={handleBilling}
                />
              </div>
            </SendQuote>
            <div
              className="offcanvas-overlay"
              onClick={() => setSendQuoteCanva({ type: "", open: false })}
            ></div>
          </>
        ) : (
          ""
        )}
        {/* {openCanvaQuote.type === "payment" && openCanvaQuote.open ? ( */}
        <>
          {/* <OrderPayment
            //   state={state}
            //   dispatchReducer={dispatchReducer}
            //   setOpenCanvaQuote={setOpenCanvaQuote}
            //   snack={snack}
            //   setSnack={setSnack}
            /> */}
          {/* <div
                        className="offcanvas-overlay"
                    //   onClick={() => setOpenCanvaQuote({ type: "", open: false })}
                    ></div> */}
        </>
        {/* // ) : (
        //   ""
        // )} */}

        {/* <CalcumateModal dialog={dialog} setDialog={setDialog} /> */}
      </>
    </>
  );
};
